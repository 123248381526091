import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationFAQ from '../assets/images/illustrations/question_state.png';

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <h4>What is Sprive?</h4>
      <p>
        Sprive is a mortgage assistant that uses smart technology to help you be mortgage free,
        faster and helps you make sure you’re always on the best deal. You can use Sprive on your iPhone or Android smartphone.
      </p>
      <br />
      <h4>How does Sprive work?</h4>
      <p>
        <ul>
            <li>Simply link your bank account</li>
            <li>Set your monthly limit and your mortgage freedom goal</li>
            <li>We’ll automatically set money aside based on your spending. The less you spend, the more we set aside without impacting your lifestyle</li>
            <li>With one tap you can make secure payments directly to your lender via Sprive or withdraw the money back to your bank should you need the funds</li>
            <li>We’ll keep you on track by sending you reminders, while you can monitor your savings and progress on your dashboards</li>
            <li>We’ll also challenge your mortgage against the market every day to help you make sure you’re always on the best deal</li>
        </ul>
      </p>
      <br />
      <h4>How is it different to setting up a direct debit?</h4>
      <p>A direct debit assumes that you spend the same each month. Sprive will automatically set aside an amount based on your spending, to help you maximise the amount of interest you save and with one tap allows you to make secure payments to your lender.</p>
      <br />
      <h4>Which lenders do you support?</h4>
      <p>Sprive is only available in the UK and currently works with Nationwide, First Direct, HSBC, Lloyds, Barclays, Santander, RBS, Virgin Money, Halifax, Natwest, Yorkshire Building Society, Coventry Building Society, and TSB Bank.</p>
      <p>We’re working hard to support more people so if your lender is not supported, then please email us at <a href="mailto:hello@sprive.com" type="email">
            hello@sprive.com
          </a>.
        We will let you know when we can help you.
      </p>
      <br />
      <h4>Are you regulated?</h4>
      <p>
          Yes, “Sprive Limited” is an appointed representative of Connect IFA Ltd for mortgage services (441505)
          and Prepay Technologies Ltd which is an electronic money institution authorised by the Financial Conduct
          Authority under the Electronic Money Regulations 2011 (FRN 900010) for the issuing of electronic money which
          are authorised and regulated by the Financial Conduct Authority. Our Financial Services Register number is
          919863.
      </p>
      <br />
      <h4>What fees do you charge?</h4>
      <p>
        We don’t charge any fees for using Sprive. We’re here to save you money, not to take money from you!
      </p>
      <br />
      <h4>How does Sprive make money?</h4>
      <p>
        Sprive is completely free for users. To help you pay off your mortgage faster and save interest, we want to make sure that you’re always on the best deal. We have a feature that challenges your mortgage against the market daily to help you find cheaper deals. Should we help you re-mortgage, we will be paid commission from your mortgage lender.
      </p>
      <br />
      <h4>Is my money safe with Sprive?</h4>
      <p>
        If you have an e-money account, then the funds in your account are stored and protected.
        With an e-money account, your money is never invested or loaned out and is safeguarded in accounts that keep your money separate from the money we use to run Sprive. As your funds are safeguarded, FSCS protection does not apply.
      </p>
      <br />
      <h4>Is it a good idea to pay off my mortgage faster?</h4>
      <p>
        We like to think so. There are plenty of reasons why putting your spare cash towards your mortgage
        just makes sense. Here are just a few...
      </p>
      <p>
        <ul>
          <li>You will pay your lender less interest</li>
          <li>You're likely to save much more than leaving that money in the bank</li>
          <li>Get access to cheaper deals when you remortgage</li>
          <li>Not having a mortgage gets you much closer to financial freedom</li>
        </ul>
        Before you start overpaying on your mortgage, it’s important you have sufficient emergency funds and check that you have paid off more expensive debts first.
      </p>
      <br />
      <h4>What are mortgage overpayments?</h4>
      <p>
        Overpayments are when you pay more towards your mortgage than the amount set by your lender.
        When done correctly, overpayments will help you be mortgage free, faster, and save you money in interest.
      </p>
      <br />
      <h4>How do I overpay my mortgage?</h4>
      <p>
        Overpayments can be confusing and many people don’t get it right.
        By using Sprive, we’ll guide you through the process and allow you to make smart payments directly to your lender.
      </p>
      <br />
      <h4>Can I make overpayments to a particular sub-account?</h4>
      <p>
        We are unable to make overpayments to a specific mortgage sub-account. If you have sub-accounts, most lenders will distribute the benefits of any overpayments made to all your sub-accounts equally.
      </p>
      <br />
      <h4>How do I avoid early repayment charges?</h4>
      <p>Most lenders allow you to overpay up to 10% of your outstanding balance a year, which for most is plenty.  For peace of mind, you can set an overpayment limit within the Sprive app.  This will help you track your payments versus your set limit and we will alert you if you get close.</p>
      <p>
        Below is a general guideline of limits set by lenders, but if you are not sure, you can confirm this with your lender. Restrictions can vary depending on the mortgage product you are on.
      </p>
      <p>
        <ol>
          <p><strong>Fixed rate</strong> - You can usually pay up to 10% of your outstanding balance per year</p>
          <p><strong>Standard Variable rate (SVR)</strong> - You can usually overpay any amount</p>
          <p><strong>Tracker/Variable rate</strong> - Limits will vary from lender to lender</p>
        </ol>
      </p>
      <p>
        Definition of ‘per year’ can vary depending on the lender too.
        Some refresh limits every calendar year (1st Jan to 31st Dec), while others refresh based on the month
        when the mortgage originally started.
      </p>
      <br />
      <h4>How does the Sprive mortgage switching feature work?</h4>
      <p>
        When you sign up to Sprive, we will automatically source your mortgage details directly from your lender. This then allows us to challenge your mortgage every day to make sure you’re always on the best deal. If we find a better mortgage deal for you, we’ll let you know and direct you to an expert who will help you secure the best deal for you. It’s completely free to use!
      </p>
      <p>
        Here are just a few ways this new feature can help you:
      </p>
      <p>
        <ul>
          <li>You’ll be able to see live mortgage deals personalised to you</li>
          <li>We’ll let you know when it’s the right time to switch</li>
          <li>Whether your deal is expiring or you’re thinking of exiting your deal early, we have experts who can help</li>
          <li>By switching with Sprive, we’ll be able to seamlessly help you make mortgage overpayments on your new mortgage and be able to automatically take into account any early repayment charges associated with your new mortgage</li>
        </ul>
      </p>
      <br />
      <h4>What are your security practices?</h4>
      <p>
        We take your security extremely seriously. We take the following steps to protect your data:
      </p>
      <p>
        <ul>
          <li>We never store or have access to your bank login details</li>
          <li>We use symmetric cryptography (AES) to store any sensitive data</li>
          <li>We use 256-bit TLS encryption to communicate between the Sprive app and our servers</li>
        </ul>
        If you would like to learn more about our approach to security and privacy, then please contact us at{' '}
          <a href="mailto:privacy@sprive.com" type="email">
            privacy@sprive.com
          </a>.
      </p>
      <br />
      <h4>How can I get in touch with you?</h4>
      <p>
        We’d love to hear from you. We’re just getting started and would love to hear your thoughts
        and comments to help us improve.
      </p>
      <p>
        You can press ‘Give Feedback’ in the menu of the app and send us a message.
        Alternatively, you can always email us at{' '}
          <a href="mailto:hello@sprive.com" type="email">
            hello@sprive.com
          </a>.
      </p>
      <br />
      <h4>How can I close or delete my Sprive account?</h4>
      <p>
        Simply send us an email with your request at{' '}
          <a href="mailto:hello@sprive.com" type="email">
            hello@sprive.com
          </a> or contact us via the app. Any money you have in your Sprive account will be refunded promptly before we close your account. Solely deleting the app from your phone does not cancel your direct debit or close your Sprive account.
      </p>
    </div>
  </section>
);

const PrivacyPage = () => (
  <>
    <SEO title="FAQ" />
    <div className="common-page faq-page">
      <HeadingSection h1="Frequently Asked Questions" image={imageIllustrationFAQ} />
      <SecondSection />
    </div>
  </>
);

export default PrivacyPage;
